/**
 *  - recipesFactory
 *  - RecipeController
 *  - AsideController
 */

(function () {
  'use strict';

  angular
    .module('mainApp')

    .factory('recipesFactory', [
      '$http',
      '$q',
      '$log',
      '$upload',
      '_',
      'appConfig',
      'sessionService',
      'toolsService',
      '$rootScope',
      function (
        $http,
        $q,
        $log,
        $upload,
        _,
        appConfig,
        sessionService,
        toolsService,
        $rootScope
      ) {
        var _recipesFactory = {};
        var deferredTags;

        function emptyGroups(recipeGroups) {
          return (
            !recipeGroups ||
            (recipeGroups &&
              recipeGroups.length === 1 &&
              recipeGroups[0].steps.length === 0)
          );
        }

        function emptyIngredient(ing) {
          return (
            !ing ||
            (ing &&
              toolsService.isEmpty(ing.extra) &&
              toolsService.isEmpty(ing.name) &&
              toolsService.isEmpty(ing.prep) &&
              toolsService.isEmpty(ing.unit))
          );
        }

        function pruneIngredients(recipe) {
          var prunedRecipe;
          if (!emptyGroups(recipe.groups)) {
            recipe.groups.forEach(function (g) {
              g.ingredients = g.ingredients.filter(function (ing) {
                return !emptyIngredient(ing);
              });

              g.steps.forEach(function (stp) {
                stp.ingredients = stp.ingredients.filter(function (ing) {
                  return !emptyIngredient(ing);
                });
              });
            });
          }
          prunedRecipe = recipe;

          return prunedRecipe;
        }

        _recipesFactory.formatIncomingRecipe = function (recipe) {
          if (!emptyGroups(recipe.groups)) {
            recipe = pruneIngredients(recipe);
          }

          if (
            recipe.videos &&
            recipe.videos.length > 0 &&
            recipe.videos[0] !== ''
          ) {
            var youtubeCode = toolsService.extractYoutubeCode(recipe.videos[0]);

            if (!toolsService.isEmpty(youtubeCode)) {
              var query = {
                rel: 0,
                showinfo: 0,
                autohide: 1,
                enablejsapi: 1,
                cc_load_policy: 1,
                modestbranding: 1,
              };

              recipe.videoUrl = toolsService.getEmbedVideo(youtubeCode, query);
            }
          }

          return recipe;
        };

        _recipesFactory.getFilters = function () {
          var deferred = $q.defer();
          deferred.resolve(mostWanted);

          return deferred.promise;
        };

        _recipesFactory.getTags = function () {
          if (angular.isUndefined(deferredTags)) {
            deferredTags = $q.defer();
            var tagsBundle = sessionService.getValue('tags');

            if (!toolsService.isEmpty(tagsBundle)) {
              deferredTags.resolve(tagsBundle);
            } else {
              var urlDestino = appConfig.mycookTouchDomain + '/tags/';
              $http({
                method: 'get',
                url: urlDestino,
              }).then(function (tags) {
                tagsBundle = {};
                // Juan está al corriente de la ñapa del replace de / por :
                var auxTags = JSON.stringify(tags.data);
                // ÑAPA
                auxTags = auxTags.replace(/\//g, appConfig.itemSeparator);
                auxTags = JSON.parse(auxTags);
                var tagKeys = Object.keys(auxTags);
                var tagRank = [];
                var tagsDietas = [];

                for (var i = 0; i < tagKeys.length; i++) {
                  var tagKey = tagKeys[i];
                  tagRank.push({
                    k: tagKey.replace(
                      appConfig.decorator,
                      appConfig.itemSeparator
                    ),
                    v: auxTags[tagKey],
                  });

                  if (
                    !toolsService.isEmpty(appConfig['tags-separator']) &&
                    !toolsService.isEmpty(appConfig['tags-separator'].diet) &&
                    tagKey.indexOf(appConfig['tags-separator'].diet) > -1
                  ) {
                    tagsDietas.push({
                      k: tagKey.replace(
                        appConfig.decorator,
                        appConfig.itemSeparator
                      ),
                      v: auxTags[tagKey],
                    });
                  }
                }
                tagsBundle.tags = auxTags;
                tagsBundle.tagsRank = tagRank;
                tagsBundle.tagsDietas = tagsDietas;

                sessionService.setValue('tags', tagsBundle);
                deferredTags.resolve(tagsBundle);
              });
            }
          } else {
            $log.debug(
              'recipesFactory > getTags > reaprovecho de promise!',
              deferredTags
            );
          }

          return deferredTags.promise;
        };

        _recipesFactory.create = function (recipe) {
          return $http({
            url: appConfig.mycookTouchDomain + '/recipe/',
            method: 'POST',
            data: recipe,
          });
        };

        _recipesFactory.edit = function (recipe) {
          return $http({
            url: appConfig.mycookTouchDomain + '/recipe/' + recipe.niceName,
            method: 'PUT',
            data: angular.toJson(recipe), // para limpiar los campos internos de angular
          });
        };

        _recipesFactory.newSize = function (recipe) {
          return $http({
            url:
              appConfig.mycookTouchDomain +
              '/recipe/' +
              recipe.niceName +
              '/newSize?web=true',
            method: 'POST',
            data: angular.toJson(recipe), // para limpiar los campos internos de angular
          });
        };

        _recipesFactory.publishRecipe = function (recipe, recipeCase) {
          $log.debug('FACTORY > publishRecipe', recipeCase, recipe);

          var deferred = $q.defer();

          // removing info we don't want to update
          delete recipe._id;
          if (recipeCase != 'size') delete recipe.region;
          delete recipe.comments;
          delete recipe.user;
          delete recipe.nutritional;
          delete recipe.designedTo;
          // virtuals
          delete recipe.accesoriesList;
          delete recipe.ingredientsSummary;

          for (var i = 0; i < recipe.groups.length; i++) {
            for (var j = 0; j < recipe.groups[i].steps.length; j++) {
              delete recipe.groups[i].steps[j].auxFields;
              delete recipe.groups[i].steps[j].auxType;
              delete recipe.groups[i].steps[j].params;
              delete recipe.groups[i].steps[j].image;
            }

            delete recipe.groups[i].ingredients;
          }

          if (recipeCase === 'new') {
            $log.debug('before publish', recipe);

            _recipesFactory.create(recipe).then(
              function postRecipeOK(response) {
                deferred.resolve(response);
              },
              function postRecipeKO(postErrors) {
                deferred.reject(postErrors);
              }
            );
          } else {
            if (recipeCase === 'edit') {
              // hay que hacer un flatten de los fields
              var recipeFields = toolsService.specialflattenObj(recipe);
              recipeFields = fixFlattenStatus(recipeFields);

              $log.debug('after flatten, before edit', recipeFields);

              _recipesFactory.edit(recipeFields).then(
                function putRecipeOK(response) {
                  deferred.resolve(response);
                },
                function putRecipeKO(postErrors) {
                  deferred.reject(postErrors);
                }
              );
            } else {
              _recipesFactory
                .newSize(recipe)
                .then(deferred.resolve, deferred.reject);
            }
          }

          return deferred.promise;
        };

        function fixFlattenStatus(recipe) {
          var status = {
            canView: recipe['status.canView'],
            favorite: recipe['status.favorite'],
            idParent: recipe['status.idParent'],
            mine: recipe['status.mine'],
            ppv: recipe['status.ppv'],
            private: recipe['status.private'],
            todo: recipe['status.todo'],
            done: recipe['status.done'],
            verified: recipe['status.verified'],
          };

          delete recipe['status.canView'];
          delete recipe['status.favorite'];
          delete recipe['status.idParent'];
          delete recipe['status.mine'];
          delete recipe['status.ppv'];
          delete recipe['status.private'];
          delete recipe['status.todo'];
          delete recipe['status.done'];
          delete recipe['status.verified'];
          delete recipe['images'];

          recipe.status = status;

          return recipe;
        }

        _recipesFactory.detectSuitableMachine = function (recipe) {
          var machine;

          if (recipe.compatibility && recipe.compatibility.current) {
            if (recipe.compatibility.current.length == 1) {
              machine = recipe.compatibility.current[0];
            } else {
              machine = recipe.compatibility.current.reduce(
                function (current, compat) {
                  var currentFloat = parseFloat(current.substring(1));
                  var compatFloat = parseFloat(compat.substring(1));

                  return compatFloat < currentFloat ? compat : current;
                },
                'S99999' //Gañanada ...
              );
            }
          } else {
            machine = 'S1';
          }

          if (machine === 'S1') {
            var designedTo = machine;
            var groups = recipe.groups;
            var found = false;
            var i, j;
            i = 0;

            if (!toolsService.isEmpty(groups) && groups.length > 0) {
              do {
                var group = groups[i];
                var steps = group.steps;
                // if !'Funciones' case
                if (!toolsService.isEmpty(steps) && steps.length > 0) {
                  j = 0;
                  do {
                    var step = steps[j];
                    if (
                      (step.temperature !== undefined &&
                        !isNaN(parseInt(step.temperature, 10)) &&
                        step.temperature > 120) ||
                      (step.speed !== undefined &&
                        !isNaN(parseInt(step.speed, 10)) &&
                        step.speed === 1)
                    ) {
                      found = true;
                      designedTo = 'S1.1';
                    }
                    j++;
                  } while (j < steps.length && !found);
                }
                i++;
              } while (i < groups.length && !found);
            }

            return designedTo;
          } else return machine;
        };

        _recipesFactory.getRecipe = function (idrec) {
          var urlDestino = appConfig.mycookTouchDomain + '/recipe/' + idrec;
          var deferred = $q.defer();

          $http({
            method: 'get',
            url: urlDestino,
          }).then(
            function (res) {
              var recipe = res.data;
              if (emptyGroups(recipe.groups)) {
                recipe.groups = [];
              }
              recipe = _recipesFactory.formatIncomingRecipe(recipe);
              recipe.designedTo = _recipesFactory.detectSuitableMachine(recipe);

              deferred.resolve(recipe);
            },
            function (error) {
              console.error(error);
              deferred.reject(error);
            }
          );

          return deferred.promise;
        };

        _recipesFactory.getRandomRecipes = function (
          categoryNiceName,
          recipeToExclude,
          numResults
        ) {
          var urlDestino =
            appConfig.mycookTouchDomain +
            '/categories/' +
            categoryNiceName +
            '/random/' +
            numResults;
          var data = { niceName: recipeToExclude };
          return $http({
            method: 'post',
            url: urlDestino + '?profile=seo',
            headers: { 'Content-type': 'application/json' },
            data: data,
          });
        };

        function getRecipesData(
          urlDestino,
          queryParams,
          queryData,
          currentPage,
          offSetItems,
          itemsInPage
        ) {
          var defer = $q.defer();
          var skip = 0;
          var itemsByPage = itemsInPage || appConfig.itemsByPage;
          queryParams = queryParams || {};
          offSetItems = offSetItems || 0;

          // Paginacion
          if (!isNaN(currentPage) && currentPage !== '' && currentPage > 0) {
            skip = itemsByPage * (currentPage - 1) - offSetItems;
            if (skip < 0) skip = 0;
          }

          queryParams.limit = itemsByPage;
          if (currentPage == 1) queryParams.limit -= offSetItems;
          queryParams.skip = skip;

          console.log(
            'recipesFactory > getRecipesDATA > urlDestino, queryParams, queryData, offSetItems, itemsByPage',
            urlDestino,
            queryParams,
            queryData,
            offSetItems,
            itemsByPage
          );

          if ($rootScope.currentUser) {
            if ($rootScope.currentUser.role.includes('admin')) {
              if (appConfig.allowedCompatsAdmin) {
                queryData['compatibility.current'] = {
                  $in: appConfig.allowedCompatsAdmin,
                };
              }
            } else {
              if (appConfig.allowedCompats) {
                queryData['compatibility.current'] = {
                  $in: appConfig.allowedCompats,
                };
              }
            }
          } else {
            if (appConfig.allowedCompats) {
              queryData['compatibility.current'] = {
                $in: appConfig.allowedCompats,
              };
            }
          }

          $http({
            method: 'post',
            url: urlDestino,
            params: queryParams,
            data: JSON.stringify(queryData),
          }).then(
            function (result) {
              var recipesData = result.data;
              defer.resolve(recipesData);
            },
            function (err) {
              defer.reject(err);
            }
          );

          return defer.promise;
        }

        _recipesFactory.searchRecipes = function (
          term,
          criteria,
          currentPage,
          offSetItems,
          itemsInPage
        ) {
          var defer = $q.defer();
          var urlDestino = appConfig.mycookTouchDomain + '/search/';

          var queryParams = {};
          var queryData = {};

          if (!toolsService.isEmpty(criteria)) {
            if (!toolsService.isEmpty(criteria.category)) {
              queryData.categoryNiceName = {};
              queryData.categoryNiceName.$in = [criteria.category];
            }

            queryData['status.idParent'] = '';

            if (!toolsService.isEmpty(criteria.difficulty)) {
              queryData.difficulty = parseInt(criteria.difficulty);
            }

            if (!toolsService.isEmpty(criteria.price)) {
              queryData.price = parseInt(criteria.price);
            }

            if (!toolsService.isEmpty(criteria.tags)) {
              queryData.tags = {};
              queryData.tags.$all = criteria.tags;
            }

            if (!toolsService.isEmpty(criteria.timeLength)) {
              var timeLengthLow = 0;
              var timeLengthHigh = -1;

              switch (criteria.timeLength.toString()) {
                case '1': // 15 min
                  timeLengthLow = 0;
                  timeLengthHigh = 901;
                  break;
                case '2': // 15 - 30 min
                  timeLengthLow = 900;
                  timeLengthHigh = 1801;
                  break;
                case '3': // 30 - 60
                  timeLengthLow = 1800;
                  timeLengthHigh = 3601;
                  break;
                case '4': // > 60
                  timeLengthLow = 3601;
                  break;
              }

              queryData.totalTime = { $gte: timeLengthLow };

              if (timeLengthHigh > -1) {
                queryData.totalTime.$lte = timeLengthHigh;
              }
            }

            if (!toolsService.isEmpty(criteria['status.favorites'])) {
              queryData['status.favorites'] = criteria['status.favorites'];
            }

            if (!toolsService.isEmpty(criteria['status.todo'])) {
              queryData['status.todo'] = criteria['status.todo'];
            }

            if (!toolsService.isEmpty(criteria['status.done'])) {
              queryData['status.done'] = criteria['status.done'];
            }

            if (!toolsService.isEmpty(criteria['verified'])) {
              var isTrueSet = criteria['verified'] === 'true';
              queryData['status.verified'] = isTrueSet;
            }

            if (!toolsService.isEmpty(criteria.user)) {
              queryData['user.niceName'] = criteria.user;
            }

            if (!toolsService.isEmpty(criteria.hasVideo)) {
              queryData.videos = {};
              queryData.videos.$not = { $size: 0 };
              queryData.videos.$ne = null;
            }

            if (!toolsService.isEmpty(criteria.sortBy)) {
              queryParams.sortBy = criteria.sortBy;
            }
          }

          var currentTerm = term || '%20';
          urlDestino = urlDestino + currentTerm + '?profile=desktopList';

          getRecipesData(
            urlDestino,
            queryParams,
            queryData,
            currentPage,
            offSetItems,
            itemsInPage
          ).then(
            function (result) {
              defer.resolve(result);
            },
            function (err) {
              defer.reject(err);
            }
          );

          return defer.promise;
        };

        _recipesFactory.getRecipesByCriteria = function (
          criteria,
          currentPage,
          offSetItems,
          itemsInPage
        ) {
          var defer = $q.defer();

          // console.log("Criteria");
          // console.log(criteria);

          if (criteria['ingredients'] && criteria['ingredients'].length > 0) {
            // si tengo ingredientes cerca aprofitament
            var urlDestino =
              appConfig.mycookTouchDomain + '/recipes/leftovers/';
          } else {
            var urlDestino = appConfig.mycookTouchDomain + '/recipes/';
          }

          var queryParams = {};
          var queryData = {};

          if (!toolsService.isEmpty(criteria)) {
            if (!toolsService.isEmpty(criteria.category)) {
              queryData.categoryNiceName = {};
              queryData.categoryNiceName.$in = [criteria.category];
            }

            // PAX
            if (
              criteria['status.idParent'] !== null ||
              criteria['status.idParent'] !== undefined
            ) {
              queryData['status.idParent'] = criteria['status.idParent'];
            }

            if (!toolsService.isEmpty(criteria.difficulty)) {
              queryData.difficulty = parseInt(criteria.difficulty);
            }

            if (!toolsService.isEmpty(criteria.price)) {
              queryData.price = parseInt(criteria.price);
            }

            if (!toolsService.isEmpty(criteria.tags)) {
              queryData.tags = {};
              queryData.tags.$all = criteria.tags;
              queryData['status.idParent'] = '';
            }

            if (!toolsService.isEmpty(criteria.timeLength)) {
              var timeLengthLow = 0;
              var timeLengthHigh = -1;
              switch (criteria.timeLength.toString()) {
                case '1': // 15 min
                  timeLengthLow = 0;
                  timeLengthHigh = 901;
                  break;
                case '2': // 15 - 30 min
                  timeLengthLow = 900;
                  timeLengthHigh = 1801;
                  break;
                case '3': // 30 - 60
                  timeLengthLow = 1800;
                  timeLengthHigh = 3601;
                  break;
                case '4': // > 60
                  timeLengthLow = 3601;
                  break;
              }
              queryData.totalTime = { $gte: timeLengthLow };

              if (timeLengthHigh > -1) {
                queryData.totalTime.$lte = timeLengthHigh;
              }
            }

            if (!toolsService.isEmpty(criteria['status.favorites'])) {
              queryData['status.favorites'] = criteria['status.favorites'];
            } else if (!toolsService.isEmpty(criteria['status.todo'])) {
              queryData['status.todo'] = criteria['status.todo'];
            } else if (!toolsService.isEmpty(criteria['status.done'])) {
              queryData['status.done'] = criteria['status.done'];
            } else {
              if (!toolsService.isEmpty(criteria.user)) {
                queryData['user.niceName'] = criteria.user;
              }
            }

            if (!toolsService.isEmpty(criteria['course'])) {
              queryData['course'] = criteria['course'];
            }

            if (!toolsService.isEmpty(criteria['verified'])) {
              var isTrueSet = criteria['verified'] === 'true';
              queryData['status.verified'] = isTrueSet;
            }

            // antes del GET
            if (!toolsService.isEmpty(criteria.hasVideo)) {
              queryData.videos = {};
              queryData.videos.$not = { $size: 0 };
              queryData.videos.$ne = null;
            }

            if (!toolsService.isEmpty(criteria.sortBy)) {
              queryParams.sortBy = criteria.sortBy;
            }

            $log.debug(
              '###RecipesFactory > getRecipesByCriteria > QUERY ',
              queryData
            );
          }

          //|| (criteria['ingredients'] && criteria['ingredients'].length > 0)
          if (
            (criteria['sortBy'] && !criteria['terms']) ||
            criteria['category'] ||
            criteria['status.favorites'] ||
            criteria['status.todo'] ||
            criteria['status.done']
          ) {
            urlDestino = urlDestino + '?profile=desktopList';
          } else {
            urlDestino =
              urlDestino + (criteria.terms || '%20') + '?profile=desktopList';
          }

          // Cuina aprofitament
          if (criteria['ingredients'] && criteria['ingredients'].length > 0) {
            // si tengo ingredientes cerca aprofitament
            var query = {};
            query.ingredients = criteria.ingredients;
            query.query = queryData;

            if (!toolsService.isEmpty(criteria.sortBy)) {
              var value = '';
              switch (criteria.sortBy) {
                case 'voted':
                  query.sort = { 'social.facebook': -1 };
                  break;
                case 'favorites':
                  query.sort = { 'social.favorite': -1 };
                  break;
                case 'commented':
                  query.sort = { 'social.comments': -1 };
                  break;
              }
            }
            queryData = query;
          }

          getRecipesData(
            urlDestino,
            queryParams,
            queryData,
            currentPage,
            offSetItems,
            itemsInPage
          ).then(
            function (result) {
              defer.resolve(result);
            },
            function (err) {
              defer.reject(err);
            }
          );

          return defer.promise;
        };

        _recipesFactory.markAsFavorite = function (userId, recipeId) {
          var urlDestino =
            appConfig.mycookTouchDomain +
            '/user/' +
            userId +
            '/favorite/' +
            recipeId;
          return $http({
            method: 'post',
            headers: { 'Content-type': 'application/json' },
            url: urlDestino,
          });
        };

        _recipesFactory.removeFromFavorite = function (userId, recipeId) {
          var urlDestino =
            appConfig.mycookTouchDomain +
            '/user/' +
            userId +
            '/favorite/' +
            recipeId;
          return $http({
            method: 'delete',
            headers: { 'Content-type': 'application/json' },
            url: urlDestino,
          });
        };

        _recipesFactory.markAsToDo = function (userId, recipeId) {
          var urlDestino =
            appConfig.mycookTouchDomain +
            '/user/' +
            userId +
            '/todo/' +
            recipeId;
          return $http({
            method: 'post',
            headers: { 'Content-type': 'application/json' },
            url: urlDestino,
          });
        };

        _recipesFactory.removeFromToDo = function (userId, recipeId) {
          var urlDestino =
            appConfig.mycookTouchDomain +
            '/user/' +
            userId +
            '/todo/' +
            recipeId;
          return $http({
            method: 'delete',
            headers: { 'Content-type': 'application/json' },
            url: urlDestino,
          });
        };

        _recipesFactory.sendMsg = function (niceName, msg, arrayOfIds, type) {
          var urlDestino =
            appConfig.mycookTouchDomain +
            '/recipe/' +
            niceName +
            '/comment/' +
            (arrayOfIds.length > 0 ? arrayOfIds.join('.') : '');
          var queryData = {};
          queryData.text = msg;
          $log.debug(
            'RecipesFactory > sendMsg POST URL: [',
            urlDestino,
            '] queryData: ',
            queryData
          );
          return $http({
            method: type == 'edit' ? 'put' : 'post',
            headers: { 'Content-type': 'application/json' },
            url: urlDestino,
            data: JSON.stringify(queryData),
          });
        };

        _recipesFactory.uploadChildImage = function (niceName, childId, file) {
          return new Promise(function (resolve, reject) {
            $upload
              .upload({
                url:
                  appConfig.mycookTouchDomain +
                  '/recipe/' +
                  niceName +
                  '/imageChild/' +
                  childId,
                method: 'POST',
                file: file,
                fileFormDataName: 'image',
              })
              .progress(function (evt) {
                $log.debug('progress1 > evt: ', evt);
                $log.debug(
                  'progress: ' +
                    parseInt((100.0 * evt.loaded) / evt.total, 10) +
                    '% file :' +
                    evt.config.file.name
                );
              })
              .success(function (data, status, headers, config) {
                resolve({ data: data, status: status });
              })
              .error(function (e) {
                $log.error('File upload Error', e);
                reject(e);
              });
          });
        };

        _recipesFactory.deleteChildImage = function (niceName, childId) {
          return $http({
            method: 'delete',
            url:
              appConfig.mycookTouchDomain +
              '/recipe/' +
              niceName +
              '/imageChild/' +
              childId,
          });
        };

        _recipesFactory.sendRate = function (
          niceName,
          rate,
          msg,
          arrayOfIds,
          type,
          user,
          _id
        ) {
          var urlDestino =
            appConfig.mycookTouchDomain + '/recipe/' + niceName + '/rate';

          // first position of rate or reply (no rating points)
          if (arrayOfIds.length < 0 || toolsService.isEmpty(rate)) {
            if (type === 'new') {
              // remove .X
              arrayOfIds = arrayOfIds[0];
            } else {
              arrayOfIds = arrayOfIds.length > 0 ? arrayOfIds.join('.') : '';
            }

            urlDestino += '/' + arrayOfIds;
          }

          var queryData = {};
          var http_method = 'post';

          if (type != 'new') {
            // edit rate == PUT
            http_method = 'put';
          }

          queryData.displayName = user.name.displayName;
          queryData.niceName = user.niceName;
          queryData.rank = user.rank;
          queryData.text = msg;
          if (!toolsService.isEmpty(rate)) queryData.rate = rate;
          if (_id) queryData._id = _id;

          $log.info(
            'RecipesFactory > sendRate ' +
              http_method.toUpperCase() +
              ' URL: [',
            urlDestino,
            '] queryData: ',
            queryData
          );
          return $http({
            url: urlDestino,
            method: http_method,
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + sessionService.getUserToken(),
            },
            data: queryData,
          });
        };

        _recipesFactory.deleteRecipeImg = function (recipeId, pos) {
          if (!pos) {
            pos = 0;
          }
          var urlDestino =
            appConfig.mycookTouchDomain +
            '/recipe/' +
            recipeId +
            '/image/' +
            pos;
          return $http({
            method: 'delete',
            headers: { 'Content-type': 'application/json' },
            url: urlDestino,
          });
        };

        _recipesFactory.deleteRecipe = function (recipeId) {
          var urlDestino = appConfig.mycookTouchDomain + '/recipe/' + recipeId;
          return $http({
            method: 'delete',
            headers: { 'Content-type': 'application/json' },
            url: urlDestino,
          });
        };

        _recipesFactory.getIngredientsSettings = function () {
          var urlDestino = appConfig.mycookTouchDomain + '/ingredientsSettings';

          var defer = $q.defer();
          var nutritionalEnabled = sessionService.getValue(
            'nutritionalEnabled'
          );

          if (!toolsService.isEmpty(nutritionalEnabled)) {
            $log.debug(
              'Service API > reaprovecho nutritionalEnabled de sessionService: ',
              nutritionalEnabled
            );
            defer.resolve(nutritionalEnabled);
          } else {
            $log.debug('Service API > Cargando ingredientsSettings');
            $http({
              method: 'GET',
              url: urlDestino,
            }).then(function (result) {
              $log.debug('Service API > ingredientsSettings loaded', result);
              nutritionalEnabled = result.data['Nutritional Enabled'];
              sessionService.setValue('nutritionalEnabled', nutritionalEnabled);
              defer.resolve(nutritionalEnabled);
            });
          }
          return defer.promise;
        };

        _recipesFactory.addIngredient = function (ingredient) {
          var name = ingredient.name;
          if (ingredient.extra !== '') name += ' (' + ingredient.extra + ')';
          var ing = [
            { ingredient: name, qty: ingredient.qty, unit: ingredient.unit },
          ];

          var url = appConfig.mycookTouchDomain + '/shoppinglist/';

          return $http({
            method: 'post',
            url: url,
            data: ing,
          }).then(function (res) {
            return res;
          });
        };

        _recipesFactory.addAllIngredients = function (recipeName) {
          var url = appConfig.mycookTouchDomain + '/shoppinglist/' + recipeName;

          return $http({
            method: 'post',
            url: url,
          }).then(function (res) {
            return res;
          });
        };

        _recipesFactory.addCompatibility = function (
          recipeNiceName,
          compatibility
        ) {
          console.log(compatibility);
          var deferredResponse = $q.defer();
          var url = appConfig.mycookTouchDomain + '/recipe/' + recipeNiceName;
          var params = {
            compatibility: compatibility,
          };

          $http({
            method: 'put',
            url: url,
            data: params,
            headers: { 'Content-type': 'application/json' },
          }).then(
            function (res) {
              deferredResponse.resolve(true);
            },
            function errorCallback(response) {
              deferredResponse.resolve(false);
            }
          );

          return deferredResponse.promise;
        };

        _recipesFactory.countRecipes = function () {
          return new Promise(function (resolve, reject) {
            var recipeCounter = sessionService.getValue('recipeCounter');

            if (recipeCounter) resolve(recipeCounter);
            else {
              $http({
                method: 'GET',
                url: appConfig.mycookTouchDomain + '/recipes/count',
              })
                .then(function (result) {
                  recipeCounter = result.data.count;
                  sessionService.setValue('recipeCounter', recipeCounter);

                  resolve(recipeCounter);
                })
                .catch(reject);
            }
          });
        };

        return _recipesFactory;
      },
    ]);
})();
