(function () {

  'use strict';

  angular.module('mainApp')

    .controller('ProfileController', ['$scope', '$rootScope', '$q', '$upload', '$location', '$log', 'appConfig', 'toolsService', 'usersManager', 'webStorage', 'machineService', 'ngDialog', 'storeFactory', '$window', 'sessionService',
      function ($scope, $rootScope, $q, $upload, $location, $log, appConfig, toolsService, usersManager, webStorage, machineService, ngDialog, storeFactory, $window, sessionService) {

        var userNiceName = webStorage.get('userNiceName');
        $rootScope.seo_robots = "nofollow, noindex";
        $scope.randomQuery = toolsService.newRandomQuery();

        if(appConfig.webSettings.shop) {
          // get state list
          storeFactory.getStatesRegister().then(function(states) {
            $scope.states = states;
          });
        }

        $scope.years = toolsService.setYears();
        $scope.months = toolsService.setMonths();
        $scope.days = toolsService.setDays();

        //retrieve logged user info
        usersManager.getUserInfo(userNiceName, 'private').then(function (user_data) {
          //select the userBirthDate on the selects
          if (!toolsService.isEmpty(user_data.profile)) {
            if ((!toolsService.isEmpty(user_data.profile.birthday))) {
              var dateSplit = toolsService.dateToArray(user_data.profile.birthday);
              $scope.birth_day = dateSplit[2];
              $scope.birth_month = dateSplit[1];
              $scope.birth_year = dateSplit[0];
            }
          }

          $scope.userData = user_data;
        });

        //retrieve users machines list
        usersManager.getMachineList().then(function (machines) {

          machines.forEach(function (machine) {
            //format the purchase date to select the values in the dropdown
            if (machine.purchaseDate) {
              var fixedDate = toolsService.dateToArray(machine.purchaseDate);
              machine.purchase_day = fixedDate[2];
              machine.purchase_month = fixedDate[1];
              machine.purchase_year = fixedDate[0];
            }
          });

          $scope.machines = machines;
        });

        $scope.sendUserImage = function () {
          if ($scope.myFiles) {
            postFiles($scope.myFiles, userNiceName);
          }
        }

        $scope.addMachine = function () {
          $scope.machines.push(machineService.getMachineSchema());
        }

        $scope.deleteUser = function () {
          ngDialog.openConfirm({
            template: 'remove-user.html',
            className: 'new-recipe',
            scope: $scope
          }).then(function (r) {

            setTimeout(function () {
              usersManager.deleteUser(userNiceName).then(function (result) {
                sessionService.setUserToken("", true);
                webStorage.set('userNiceName', "");
                webStorage.set('mcUserToken', "");
                var urlToRedirect = $rootScope.url('home');
                $window.location.href = urlToRedirect;
              });
            }, 1000);
          });
        }

        $scope.deleteMachine = function (index) {
          if ($scope.machines.length == 1) {
            $scope.machines = [];
          } else {
            $scope.machines.splice(index, 1);
          }
        }

        $scope.submitForm = function (valid, userData) {
          if ((!toolsService.isEmpty($scope.birth_year)) && (!toolsService.isEmpty($scope.birth_month)) && (!toolsService.isEmpty($scope.birth_day))) {
            if (!userData.profile) {
              userData.profile = {
                birthday: toolsService.getStringDate($scope.birth_year, $scope.birth_month, $scope.birth_day)
              };
            } else {
              userData.profile.birthday = toolsService.getStringDate($scope.birth_year, $scope.birth_month, $scope.birth_day);
            }
          }

          //TODO: validacion de que todos los campos del formulario de maquinas estan llenos
          if ($scope.machines) { //Si al editar el perfil tinc maquina la verifico, sino llanço la actualitzacio directament

            if (!machineService.hasDuplicatedMachines($scope.machines)) {

              machinesExistAndFreeOrOwned($scope.machines, userNiceName).then(function (allMachinesGood) {

                if (!allMachinesGood) { //some machines not good, cant create the user
                  $scope.errorMachines = true;

                } else { //all machines good, can create the user
                  $scope.errorMachines = false;
                  var machinesUpdateFormat = machineService.formatMachineList($scope.machines);
                  usersManager.updateMachineList(machinesUpdateFormat, userNiceName).then(function (response) {
                    if (response.status == '200') {
                      usersManager.updateUser(userData).then(function (res) {
                        if (res.status == '200') {
                          $scope.success = true;
                        } else {
                          $scope.success = false;
                        }
                      });
                    } else {
                      $scope.success = false;
                    }
                  });
                }
              });

            } else {
              $log.debug('UpdateProfileController -> Maquinas duplicadas');
            }

          } else { //no tinc que actualitzar maquines, actualitzo directament l'usuari

            usersManager.updateUser(userData).then(function (res) {
              if (res.status == '200') {
                $scope.success = true;
              } else {
                //TODO: informar de que hi ha hagut un error actualitzan el perfil
                $scope.success = false;
              }
            });
          }
        }

        function postFiles(myFiles, userNiceName) {

          for (var i = 0; i < myFiles.length; i++) {

            var aFile = myFiles[i];
            var newfile2Send;

            if (Object.prototype.toString.call(aFile) === '[object File]') {
              newfile2Send = aFile;
            } else if (aFile.indexOf(',') > -1) {

              var blobBin = atob(aFile.split(',')[1]);
              var array = [];
              for (var j = 0; j < blobBin.length; j++) {
                array.push(blobBin.charCodeAt(j));
              }
              newfile2Send = new Blob([new Uint8Array(array)], {
                type: 'image/jpeg',
                name: 'new-image.jpg'
              }); // image/png
            }

            if (newfile2Send) {
              uploadFile(userNiceName, newfile2Send);
            }
          }
        }

        function uploadFile(userNiceName, newfile2Send) {

          $upload.upload({
            url: appConfig.mycookTouchDomain + '/user/' + userNiceName + '/image',
            method: 'POST',
            file: newfile2Send, // single file or a list of files. list is only for html5
            fileFormDataName: 'image', // file formData name ('Content-Disposition'), server side request form name
            // could be a list of names for multiple files (html5). Default is 'file'
            //formDataAppender: function(formData, key, val){}  // customize how data is added to the formData.
            // See #40#issuecomment-28612000 for sample code
          }).progress(function (evt) {
            $scope.imageProgress = parseInt(100.0 * evt.loaded / evt.total, 10) + '% file :' + evt.config.file.name;
          }).success(function (data, status, headers, config) {
            // file is uploaded successfully
            $scope.successPhotoUpload = true;
            $scope.errorPhotoUpload = false;
            $scope.randomQuery = toolsService.newRandomQuery();
          }).error(function (e) {
            $log.error("EDITPROFILE File upload Error", e);
            $scope.errorPhotoUpload = true;
            $scope.successPhotoUpload = false;
          });
        }

        function machinesExistAndFreeOrOwned(machines, userNiceName) {

          var defer = $q.defer();

          var responses = [];
          var final_response_value = false;

          if (machines.length == 0) {
            defer.resolve(true);
          } else {

            machines.forEach(function (machine, index) {
              machine.serial.compatibility_code = (machine.serial.compatibility_code).toUpperCase();
              responses[index] = machineService.isEmptyMachine(machine.serial);
            });

            $q.all(responses).then(function (all_result) {

              var final_results = [];

              all_result.forEach(function (result, index) {

                if (result.status == 404) {
                  $log.debug('La maquina ' + (index + 1) + ' no existe o esta en uso');
                  final_results[index] = false;
                } else {
                  if ((!toolsService.isEmpty(result.data.info.owner)) && (result.data.info.owner != userNiceName)) {
                    $log.debug('La maquina ' + (index + 1) + ' esta en uso');
                    final_results[index] = false;
                  } else {
                    final_results[index] = true;
                  }
                }
              });

              final_response_value = true;

              final_results.forEach(function (result) {
                $log.debug('CHECKMACHINE -> result ', result);
                if (!result) {
                  final_response_value = false;
                }
              });

              defer.resolve(final_response_value);

            });
          }

          return defer.promise;
        }

      }
    ]);

})();