(function () {
  'use strict';

  angular
    .module('mainApp')

    .controller('OrderFinishedController', [
      '$location',
      '$routeParams',
      '$rootScope',
      '$cookies',
      'orderSvc',
      'serviceStoreAnalytics',
      'AffiliatesFactory',
      function (
        $location,
        $routeParams,
        $rootScope,
        $cookies,
        orderSvc,
        serviceStoreAnalytics,
        AffiliatesFactory
      ) {
        var orderId = $routeParams.id;
        var signature = $routeParams.Ds_Signature;
        var merchant = $routeParams.Ds_MerchantParameters;

        console.log('OrderFinishedController');

        if (orderId && signature && merchant) {
          orderSvc.get(orderId, signature, merchant).then(
            function (order) {
              if (order.data) {
                order = order.data;
                console.log('FINISH: ', order);
                
                if (order.state && order.state == 'Paid') {
                  if (!order.analytics) {
                    serviceStoreAnalytics.checkoutProcess(5, 'response-ok');
                    serviceStoreAnalytics.purchase(order);
                    orderSvc.markAsSent(order.id);
                  }

                  AffiliatesFactory.removeActive();
                  AffiliatesFactory.removeExternal();
                } else if (order.paymentInfo && order.paymentInfo.dsResponse) {
                  serviceStoreAnalytics.checkoutProcess(5, 'response-ko');
                } else if (
                  order.state &&
                  ['WaitingPayment', 'Error'].includes(order.state)
                ) {
                  serviceStoreAnalytics.checkoutProcess(5, 'response-ko');
                }
              } else {
                serviceStoreAnalytics.checkoutProcess(5, 'response-ko');
              }

              $location
                .path($rootScope.url('store-payment-response'))
                .search('id', orderId)
                .search('Ds_Signature', signature)
                .search('Ds_MerchantParameters', merchant);
            },
            function (data) {
              $location.path($rootScope.url('notFound'));
            }
          );
        } else {
          $location
            .path($rootScope.url('store-payment-response'))
            .search('id', orderId)
            .search('Ds_Signature', signature)
            .search('Ds_MerchantParameters', merchant);
        }
      },
    ]);
})();
