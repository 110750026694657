(function () {

	'use strict';

	angular.module('mainApp')

		.filter('maximumsteps', ['_', function(_) {
	    return function (object, number) {
        return _.slice(object, 0, number);
	    };
		}])

		.filter('accessoriesOutsideMycook', ['filtersManager', '_', function(filtersManager, _) {
			return function (object) {
				var filtersBundle = filtersManager.getFilters();
				var availableAccessories = filtersBundle.accessoriesNoMycook;

				var filteredAccessories = _.filter(object, function(n) {
					return (availableAccessories.indexOf(n.key) > -1) ? true : false;
				});

        return filteredAccessories;
	    };
		}]);
})();