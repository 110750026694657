(function () {
  'use strict';

  angular
    .module('mainApp')

    .controller('CartController', [
      '$scope',
      '$rootScope',
      'ngCart',
      '_',
      function ($scope, $rootScope, ngCart, _) {
        $scope.ngCart = ngCart;
        // access to saRoute url
        $scope.url = $rootScope.url;
      },
    ])

    .controller('CartPopupController', [
      '$rootScope',
      '$filter',
      '$timeout',
      '$scope',
      function ($rootScope, $filter, $timeout, $scope) {
        $scope.showPopUp = false;

        $rootScope.$on('ngCart:itemAdded', function (e, item) {
          $scope.showPopUp = true;

          $scope.view_cart_url = $rootScope.url('store-cart');

          $scope.txt = $filter('translateVariables')(
            $rootScope.translations.shop.common.add_to_cart_text,
            item._data.name
          );

          $timeout(function () {
            $scope.showPopUp = false;
          }, 2500);
        });
      },
    ])

    .controller('CartPageController', [
      '$http',
      '$routeParams',
      '$scope',
      '$rootScope',
      '$window',
      'appConfig',
      'ngCart',
      'webStorage',
      'sessionService',
      '_',
      'storeFactory',
      'serviceStoreAnalytics',
      '$cookies',
      '$log',
      '$timeout',
      'AffiliateProductsFactory',
      'AffiliatesFactory',
      function (
        $http,
        $routeParams,
        $scope,
        $rootScope,
        $window,
        appConfig,
        ngCart,
        webStorage,
        sessionService,
        _,
        storeFactory,
        serviceStoreAnalytics,
        $cookies,
        $log,
        $timeout,
        AffiliateProductsFactory,
        AffiliatesFactory
      ) {
        var ap = [];
        var isAffiliate = AffiliatesFactory.getActive();

        AffiliateProductsFactory.get().then(function (prods) {
          ap = prods;
        });

        $scope.getDiscountOrigin = function (item) {
          var itemDiscountOrigin =
            $rootScope.translations.shop.cart.discount_origin.shop;

          if (item.getVoucherPrice()) {
            itemDiscountOrigin = ngCart.getCoupon();
          } else if (
            !ngCart.getCoupon() ||
            ngCart.getDiscountType() === 'affiliate'
          ) {
            if (item._landing) {
              itemDiscountOrigin =
                $rootScope.translations.shop.cart.discount_origin.landing;
            } else if ($scope.isAffiliateProduct(item.getNiceName())) {
              itemDiscountOrigin =
                $rootScope.translations.shop.cart.discount_origin.affiliate;
            }
          }

          return itemDiscountOrigin;
        };

        $scope.getDiscountString = function () {
          var multiplier = ngCart.getDiscountMultiplier();

          return multiplier > 1
            ? $rootScope.translations.shop.cart.discount +
                ' ' +
                ngCart.getCoupon() +
                ' x' +
                multiplier
            : $rootScope.translations.shop.cart.discount +
                ' ' +
                ngCart.getCoupon();
        };

        $scope.isAffiliateProduct = function (niceName) {
          console.log(
            'isAffiliateProduct: ',
            isAffiliate,
            ap.includes(niceName)
          );
          return isAffiliate && ap.includes(niceName);
        };

        serviceStoreAnalytics.checkoutProcess(1);

        /* Hay que revisar esto! */
        //$scope.originalPriceProds = ['mycook-touch', 'mycook-touch-black-edition', 'mycook-easy', 'mycook-one', 'roner-clip', 'nature', 'tasty-n-cream', 'brownie-co', 'cakepop-co', 'envasadora-vac6000', 'bolsas-vac6000-30x35cm', 'bolsas-vac6000-20x30cm', 'rollos-vac6000-22cmx6m', 'robot-aspirador-lucid-i10', 'cafetera-espresso-automatic-coffeemaker', 'aspirador-escoba-ultimate-digital-fuzzy', 'aspirador-escoba-ultimate-digital', 'batidora-bapi-1200-premium-complet', 'batidora-bapi-1200-inox', 'crepera-s163', 'gofrera-g594', 'fondue-ff2', 'palomitera-pop-corn', 'pichurrin'];
        // $scope.discountOrigin = "mycook.es";

        // if ($cookies.get('AFFILIATE_MYCOOK')) {
        //   $scope.affiliatePrices = true;
        //   $scope.discountOrigin = $cookies.get('AFFILIATE_MYCOOK');
        // } else {
        //   $scope.affiliatePrices = false;
        // }

        // if ($location.search().landing) {
        //   $scope.criteoPrices = true;
        //   $scope.discountOrigin = '';
        // } else {
        //   $scope.criteoPrices = false;
        // }

        function addURLProdsToCart(prodStr) {
          var list_products = prodStr.split(',');
          var promises = [];

          angular.forEach(list_products, function (item) {
            promises.push(storeFactory.getProduct(item));
          });

          return new Promise(function (resolve, reject) {
            Promise.all(promises).then(function (response) {
              var allBuyable = response.reduce(
                function (p, n) {
                  return p.isBuyable && n.isBuyable;
                },
                { isBuyable: true }
              );

              if (allBuyable) {
                angular.forEach(response, function (product) {
                  //if (!checkCartProduct(product.niceName)) {
                  var landing = $routeParams['landing'] ? true : false;
                  var productPrice = $routeParams['landing']
                    ? product.landingPrice || product.price
                    : AffiliatesFactory.getActive()
                    ? product.affiliatePrice || product.price
                    : product.price;

                  ngCart.addItem(
                    product.niceName,
                    product.name,
                    productPrice,
                    product.originalPrice,
                    1,
                    product,
                    landing,
                    true
                  );
                  //}
                });
              } else {
                var firstUnBuyable = response.find(function (r) {
                  return !r.isBuyable;
                });
                $window.location.href =
                  '/tienda/producto/' + firstUnBuyable.niceName;
              }

              resolve();
            }, reject);
          });
        }

        if ($routeParams['products'] && $routeParams['coupon']) {
          addURLProdsToCart($routeParams['products']).then(function () {
            var first_coupon = $routeParams['coupon']
              .split(',')
              .map(function (cup) {
                return cup.toUpperCase().replace(/\s/g, '').trim();
              })[0];

            ngCart.replaceCoupon(first_coupon);

            // angular.forEach(list_coupons, function (item) {
            //   ngCart.setCoupon(item);
            // });
          }, $log.error);
        } else {
          if ($routeParams['products']) {
            addURLProdsToCart($routeParams['products']);
          }

          if ($routeParams['coupon']) {
            var first_coupon = $routeParams['coupon']
              .split(',')
              .map(function (cup) {
                return cup.toUpperCase().replace(/\s/g, '').trim();
              })[0];

            ngCart.replaceCoupon(first_coupon);
          }
        }

        // Mautic + coupones
        // Mycook: 22225
        // Roner: 27227
        // Jarra completa: 4734

        function checkCartProduct(niceName) {
          var inCart = false;
          angular.forEach(ngCart.getItems(), function (item) {
            if (item._niceName === niceName) inCart = true;
          });

          return inCart;
        }

        // Descuento Paco
        if ($routeParams['descuento']) {
          storeFactory.getProduct('mycook-touch').then(function (product) {
            if (!checkCartProduct(product.niceName))
              ngCart.addItem(
                product.niceName,
                product.name,
                product.price,
                product.originalPrice || product.price,
                1,
                product
              );
            ngCart.setCoupon('descuento');
          });
        }

        // Descuento Renove miembros club
        if ($routeParams['descuento-exclusivo']) {
          storeFactory.getProduct('mycook-touch').then(function (product) {
            if (!checkCartProduct(product.niceName))
              ngCart.addItem(
                product.niceName,
                product.name,
                product.price,
                product.originalPrice || product.price,
                1,
                product
              );
            ngCart.setCoupon('exclusivomiembrosclub');
          });
        }

        // Descuento Ebook
        if ($routeParams['descuento-ebook']) {
          storeFactory.getProduct('mycook-touch').then(function (product) {
            if (!checkCartProduct(product.niceName))
              ngCart.addItem(
                product.niceName,
                product.name,
                product.price,
                product.originalPrice || product.price,
                1,
                product
              );
            ngCart.setCoupon('descuentoebook');
          });
        }

        // Descuento Roner
        if ($routeParams['descuento-roner']) {
          storeFactory.getProduct('roner-clip').then(function (product) {
            if (!checkCartProduct(product.niceName))
              ngCart.addItem(
                product.niceName,
                product.name,
                product.price,
                product.originalPrice || product.price,
                1,
                product
              );
            ngCart.setCoupon('descuentoroner');
          });
        }

        // cupon mycook + jarra
        if ($routeParams['pack-mycook-touch']) {
          var has_jarra = false,
            has_mycook = false;

          angular.forEach(ngCart.getItems(), function (item) {
            if (item._niceName == 'mycooktouch') has_mycook = true;
            if (item._niceName == 'jarra-conjunto-completo') has_jarra = true;
          });
        }

        $scope.setQuantity = function (item, quantity, relative) {
          // set quantity in ngCart
          item.setQuantity(quantity, relative);

          var state = 'add';
          if (quantity < 0) state = 'remove';

          serviceStoreAnalytics.addRemoveFromCart(
            state,
            item,
            ngCart.getCoupon()
          );
        };

        // access to saRoute url
        $scope.url = $rootScope.url;

        $scope.ngCart = ngCart;

        $scope.voucher = null;
        $scope.voucherMessage = '';
        $scope.voucherIsApplied = false;

        $scope.getNumber = function (num) {
          num = isNaN(parseInt(num)) ? [] : parseInt(num);
          return new Array(num);
        };

        //Refresh content from localStorage
        //ngCart.$refresh();

        $scope.$back = function () {
          window.history.back();
        };

        /////ngCart.setShipping(appConfig.shop.shippingCost);

        $scope.cartIsEmpty = function () {
          return ngCart.isEmpty();
        };

        //calculatePrice/:discount/:userNiceName
        $scope.addVoucher = function (code) {
          ngCart.setCoupon(code);
        };

        $scope.removeVoucher = function () {
          var discountType = ngCart.getDiscountType();
          if (discountType === 'affiliate') AffiliatesFactory.removeActive();
          isAffiliate = AffiliatesFactory.getActive();

          ngCart.setCoupon(null);
          $scope.voucher = null;
          $scope.voucherMessage = '';
          $scope.voucherIsApplied = false;
        };

        $scope.changeVoucher = function () {
          $scope.voucher = $scope.voucher
            .toUpperCase()
            .replace(/\s/g, '')
            .trim();
        };

        if (ngCart.getCoupon()) {
          $scope.voucher = ngCart.getCoupon();
          updateCartWithCoupon();
        }

        var deregisterEvent = $rootScope.$on(
          'ngCart:change',
          function (ev, args) {
            // revisar codigo descuento y llamar a funcion
            updateCartWithCoupon();
          }
        );

        $scope.$on('$destroy', function () {
          deregisterEvent();
        });

        var pending;

        function updateCartWithCoupon() {
          if (pending) $timeout.cancel(pending);

          pending = $timeout(function () {
            var cart = _.clone(ngCart.getCart());

            if (ngCart.getCoupon()) {
              if (!$scope.voucher) {
                $scope.voucher = ngCart.getCoupon();
              }

              cart.voucher = { code: ngCart.getCoupon() };

              var leanOrder = cleanCart(cart);

              var userNiceName = webStorage.get('userNiceName') || 'guest';
              //console.log('CartPageController > updateCartWithCoupon > userNiceName', userNiceName, 'leanOrder', leanOrder);

              $http({
                method: 'post',
                headers: {
                  Authorization: 'Bearer ' + sessionService.getUserToken(),
                },
                url:
                  appConfig.localApiBasePath +
                  '/calculatePrice/' +
                  ngCart.getCoupon() +
                  '/' +
                  userNiceName,
                data: JSON.stringify(leanOrder),
              }).then(
                function successCallback(response) {
                  // console.log("CartPageController > successCallback", response, 'totalDiscount:', response.data.totalDiscount, 'total:', response.data.total);
                  ngCart.setDiscount(response.data.totalDiscount);
                  ngCart.setTotalDiscount(response.data.total);

                  if (response.data.discountType !== 'affiliate') {
                    ngCart.setDiscountMultiplier(response.data.multiplier);
                    ngCart.setDiscountType('voucher');

                    if ((response.status = 200)) {
                      // console.log('CartPageController > order status 200', response);

                      $scope.voucherIsApplied = true;
                      $scope.voucherMessage =
                        '<p>' +
                        $rootScope.translations.shop.cart.promotional_code
                          .messages.valid_discount +
                        '</p>';

                      if (response.data.discountType === 'fixedPrice') {
                        for (var i in response.data.products) {
                          var responseProduct = response.data.products[i];

                          if (responseProduct.unitPriceWithDiscount) {
                            var my_item = ngCart.getItemByNiceName(
                              responseProduct._niceName
                            );

                            if (my_item)
                              my_item.setVoucherPrice(
                                responseProduct.unitPriceWithDiscount
                              );
                          }
                        }

                        ngCart.setIsOrderDiscount(false);
                      } else {
                        ngCart.setIsOrderDiscount(true);
                      }
                    }

                    isAffiliate = AffiliatesFactory.getActive();
                  } else {
                    var currentAffiliate = AffiliatesFactory.getActive();

                    if (currentAffiliate !== response.data.affiliate)
                      AffiliatesFactory.removeActive();

                    AffiliatesFactory.setActive(response.data.affiliate).then(
                      function () {
                        isAffiliate = AffiliatesFactory.getActive();
                      }
                    );

                    ngCart.setDiscountType('affiliate');

                    $scope.voucherIsApplied = true;
                    $scope.voucherMessage =
                      '<p>' +
                      $rootScope.translations.shop.cart.promotional_code
                        .messages.valid_discount +
                      '</p>';
                  }
                },
                function errorCallback(response) {
                  // console.log("CartPageController > errorCallback", response);

                  if (ngCart.getCoupon()) {
                    $scope.voucherIsApplied = false;
                    errorHandling(response.data);
                  }
                }
              );
            } else {
              //////////////////////
              ngCart.setDiscount(null);
              ngCart.setTotalDiscount(null);
              ///////////////////
            }
          }, 500);
        }

        function cleanCart(fullCart) {
          // if (ngCart.getOrderId()) {
          //   cart.orderId = ngCart.getOrderId();
          // }

          if (AffiliatesFactory.getActive() != null) {
            fullCart.affiliate = AffiliatesFactory.getActive();
          }

          if (AffiliatesFactory.getExternal() != null) {
            fullCart.external_affiliate = AffiliatesFactory.getExternal();
          }

          if ($cookies.get('initialTrafficSource') != null) {
            fullCart.its = $cookies.get('initialTrafficSource');
          }

          if ($cookies.get('lastTrafficSource') != null) {
            fullCart.lts = $cookies.get('lastTrafficSource');
          }

          var leanCart = _.pick(fullCart, [
            'shipping',
            'shippingAddress',
            'taxRate',
            'voucher',
            'comments',
            'sendAsGift',
            'customerValue',
            'orderId',
            'affiliate',
            'external_affiliate',
            'lts',
            'its',
          ]);
          var leanItems = [];

          fullCart.items.forEach(function (item) {
            var leanItem = _.pick(item, [
              '_niceName',
              '_name',
              '_price',
              '_quantity',
              '_data',
              '_landing',
            ]);
            delete leanItem.$$hashKey;
            delete leanItem._data.relatedProducts;
            delete leanItem._data.$$hashKey;
            leanItems.push(leanItem);
          });

          leanCart.items = leanItems;
          return leanCart;
        }

        function errorHandling(data) {
          // coupon errors
          // 20 - Discount code applied correctly
          // 21 - date range
          // 22 - Discount exceded valid uses
          // 23 - Discount not match user
          // 24 - Discount not match any product of your cart
          // 25 - Invalid discount code
          // 26 - You can't mix coupon codes
          // 27 - no estan todos los productos de un pack
          // 28 - Producto fuera del rango de precios
          // 29 - Error aplicando el descuento

          $scope.voucherMessage = '';

          // console.log('CartPageController > errorHandling', data);

          var removeVoucher = false;

          if (typeof data !== 'undefined' || data.length > 0) {
            data.forEach(function (item) {
              // coupon errors
              if (item.errorCode >= 21 && item.errorCode <= 29) {
                removeVoucher = true;
                $scope.voucherMessage +=
                  '<p>' +
                  $rootScope.translations.shop.cart.promotional_code.messages[
                    item.errorCode
                  ] +
                  '</p>';
              }
            });
          }

          if (removeVoucher) {
            $scope.voucherIsApplied = false;
            ngCart.setCoupon(null);
          }
        }
      },
    ]);
})();
