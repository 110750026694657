
(function(){

  'use strict';

  angular.module('mainApp')

    .controller("LandingsComprarController", ['$scope', '$route', '$q', '$log', '$location', '$rootScope', 'storeFactory', 'seoService','serviceMautic','serviceStoreAnalytics', 'appConfig',
      function ($scope, $route, $q, $log, $location, $rootScope, storeFactory, seoService, serviceMautic, serviceStoreAnalytics, appConfig) {
        $log.debug("LandingsComprarController", $route.current.name, $route.current.originalPath);

        var seoInfo = seoService.searchSeoAtTranslationsLandings();

        $scope.recipesCounter = $rootScope.translations.recipesCounter;

        if (seoInfo) {
          seoService.setTitle(seoInfo.title);
          seoService.setDescription(seoInfo.description);
        }

        var machine = $route.current.name.replace('comprar-', '');

        var responses = [];
        var listMachines = ['mycook-easy', 'mycook', 'mycook-legend', 'mycook-touch', 'mycook-touch-black-edition', 'mycook-one'];

        $scope.email_stock = '';

        $scope.submitStockForm = function(email, niceName, price) {
          var data = {
            "email": email,
            "formId": '29',
            "modelo_mycook": niceName,
            "precio": price
          };

          var productUrl = $location.path();
          serviceMautic.sendToMautic(data, '', productUrl).then(function(response) {
            $scope.email_stock = '';
            $scope.formMessageOK = true;
            $scope.formMessageKO = false;
          }, function(error) {
            $log.debug(error);
            $scope.formMessageKO = true;
            $scope.formMessageOK = false;
          });
        };

        listMachines.forEach(function (machine, index) {
          responses[index] = storeFactory.getProduct(machine);
        });

        $q.all(responses).then(function (machinesResult) {
          var result = [];

          machinesResult.forEach(function (machine) {
            result[machine.niceName] = machine;
          });

          $scope.current = machine;
          $scope.products = result;

          serviceStoreAnalytics.singleProductView(result[machine]);

          $scope.slickConfig = {
            method: {},
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  centerMode: true,
                  slidesToShow: 3,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };
        });
    }]);
  })();
