(function() {
  angular
    .module("mainApp")

    .factory("affiliatesContactFactory", [
      "$http",
      "$q",
      "appConfig",
      "toolsService",
      function($http, $q, appConfig, toolsService) {
        var _contactFactory = {};

        _contactFactory.sendContactInformation = function(userData) {
          deferredResponse = $q.defer();
          var urlDestino = appConfig.localApiBasePath + "/affiliatecontact";

          var message = userData.message || userData.website_information;

          var data = {
            name: userData.name,
            topic: userData.subject.name,
            last_name: userData.last_name,
            email: userData.mail,
            phone: userData.phone,
            company_name: userData.company_name,
            cif: userData.cif,
            fiscal_address: userData.fiscal_address,
            cp: userData.cp,
            city: userData.city,
            province: userData.province,
            webSite: userData.web,
            instagram: userData.instagram,
            facebook: userData.facebook,
            twitter: userData.twitter,
            youtube: userData.youtube,
            message: message,
            isCompany: userData.company_autonomo || false,
            date: new Date().toISOString()
          };

          $http({
            method: "post",
            data: data,
            url: urlDestino
          }).then(
            function(res) {
              deferredResponse.resolve(true);
            },
            function errorCallback(response) {
              deferredResponse.resolve(false);
            }
          );
        };

        return _contactFactory;
      }
    ])

    .controller("AffiliatesContactController", [
      function($scope, affiliatesContactFactory, $location, legalTerms, _, $rootScope) {
        $scope.userLegalData = {};
        $scope.signature_contract = "deactivated";
        $scope.anonymousUserData = true;

        // La desesperación provoca ñapotes. Lo siento.
        $scope.subjects = [
          {
            name: $rootScope.translations.affiliates.contact.form.subject_join, //"Quiero unirme a Mycook Afiliados",
            id: "subject_join"
          },
          {
            name: $rootScope.translations.affiliates.contact.form.subject_info, //"Quiero más información"
            id: "subject_info"
          }
        ];

        $scope.urlParameter = function() {
          var parameter = $location.search();
          $scope.changeSelectOption(parameter);
        };

        $scope.changeSelectOption = function(param) {
          if (param.contact === undefined) {
            $scope.selectedItem = "join";
            $scope.userData = {
              subject: $scope.subjects[0],
              company_autonomo: true
            };
          } else {
            $scope.selectedItem = "doubts";
            $scope.userData = {
              subject: $scope.subjects[1]
            };
          }
        };

        $scope.cancelContract = function(userLegalData) {
          $scope.signature_contract = "deactivated";
          $scope.contract_not_accepted = true;
        };

        $scope.acceptContract = function(validForm, userLegalData) {
          $scope.signature_contract = "loading";
          $scope.contract_not_accepted = false;
          var sendToApi = new Array();

          for (var k in userLegalData) {
            if (userLegalData[k]) {
              sendToApi.push(k);
            }
          }

          legalTerms
            .acceptLegalTerms("multi", sendToApi, $scope.userData.mail)
            .then(function(result) {
              sendContactInformation();

              $scope.signature_contract = "deactivated";
            });
        };

        function sendContactInformation() {
          affiliatesContactFactory
            .sendContactInformation($scope.userData)
            .then(function(response) {
              if (response === true) {
                $scope.contactForm.$setPristine();
                $scope.userData = {};
                $scope.successContact = true;
                $scope.signature_contract = "deactivated";
              } else {
                $scope.successContact = false;
              }
            });
        }

        $scope.signContract = function() {
          legalTerms.getLegalList().then(function(legalList) {
            if (_.indexOf(legalList, "affiliateContactConditions") > -1) {
              $scope.signature_contract = "activated";

              legalTerms
                .getLegalTerms(["affiliateContactConditions"])
                .then(function(legal) {
                  $scope.legal = legal;
                  $scope.signature_contract = "activated";
                });
            } else sendContactInformation();
          });
        };

        $scope.showNotCheck = function() {
          $scope.notCheck = true;
        };

        $scope.hideNotCheck = function() {
          $scope.notCheck = undefined;
        };

        var init = function() {
          $scope.urlParameter();
        };

        init();
      }
    ]);
})();
