(function () {
  'use strict';

  angular
    .module('mainApp')

    .factory('filtersManager', [
      '$rootScope',
      function ($rootScope) {
        var recipesManager = {};

        var ranges = {
          S1_mycook: {
            normal: {
              time: {
                min: 5,
                max: 5940,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 30,
                      },
                    ],
                  },
                ],
              },
              temp: {
                min: 40,
                max: 120,
                step: 10,
              },
              speed: {
                min: 1,
                max: 10,
              },
            },
            saute: {
              time: {
                min: 5,
                max: 5940,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 30,
                      },
                    ],
                  },
                ],
              },
              temp: {
                min: 120,
                max: 120,
                step: 0,
              },
              speed: {
                k: 'S',
                v: '1/S',
              },
            },
            knead: {
              time: {
                min: 5,
                max: 180,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 30,
                      },
                    ],
                  },
                ],
              },
              temp: null,
              speed: {
                k: 'A',
                v: $rootScope.translations.common.speeds.knead,
              },
            },
            turbo: {
              time: null,
              temp: null,
              speed: {
                k: 'Turbo',
                v: $rootScope.translations.common.speeds.turbo,
              },
              hits: {
                min: 1,
                max: 9,
              },
            },
            progressive: {
              time: {
                min: 10,
                max: 900,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 30,
                      },
                    ],
                  },
                ],
              },
              temp: null,
              speed: {
                k: 'Progressiva',
                v: $rootScope.translations.common.speeds.progressive,
              },
              ranges: {
                c: { min: 2, max: 10 },
                h: { min: 2, max: 5 },
              },
            },
            'slow-cook': null,
            'sous-vide': null,
            steam: {
              time: {
                min: 5,
                max: 5940,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 30,
                      },
                    ],
                  },
                ],
              },
              temp: {
                min: 120,
                max: 120,
                step: 10,
              },
              speed: {
                min: 1,
                max: 5,
              },
            },
            kettle: null,
            "high-temperature": null
          },
          S1: {
            normal: {
              time: {
                min: 5,
                max: 5940,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 30,
                      },
                    ],
                  },
                ],
              },
              temp: {
                min: 40,
                max: 140,
                step: 10,
              },
              speed: {
                min: 1,
                max: 10,
              },
            },
            saute: {
              time: {
                min: 5,
                max: 5940,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 30,
                      },
                    ],
                  },
                ],
              },
              temp: {
                min: 140,
                max: 140,
                step: 0,
              },
              speed: {
                k: 'S',
                v: '1/S',
              },
            },
            knead: {
              time: {
                min: 5,
                max: 180,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 30,
                      },
                    ],
                  },
                ],
              },
              temp: null,
              speed: {
                k: 'A',
                v: $rootScope.translations.common.speeds.knead,
              },
            },
            turbo: {
              time: null,
              temp: null,
              speed: {
                k: 'Turbo',
                v: $rootScope.translations.common.speeds.turbo,
              },
              hits: {
                min: 1,
                max: 9,
              },
            },
            progressive: {
              time: {
                min: 10,
                max: 900,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 30,
                      },
                    ],
                  },
                ],
              },
              temp: null,
              speed: {
                k: 'Progressiva',
                v: $rootScope.translations.common.speeds.progressive,
              },
              ranges: {
                c: { min: 2, max: 10 },
                h: { min: 2, max: 5 },
              },
            },
            'slow-cook': null,
            'sous-vide': null,
            steam: {
              time: {
                min: 5,
                max: 5940,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 30,
                      },
                    ],
                  },
                ],
              },
              temp: {
                min: 120,
                max: 140,
                step: 10,
              },
              speed: {
                min: 1,
                max: 5,
              },
            },
            kettle: null,
            "high-temperature": null
          },
          S2: {
            normal: {
              time: {
                min: 1,
                max: 5940,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 1,
                        max: 10,
                      },
                      {
                        min: 15,
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 5,
                      },
                    ],
                  },
                ],
              },
              temp: {
                min: 37,
                max: 140,
                step: 1,
              },
              speed: {
                min: 1,
                max: 10,
                step: 0.5,
              },
            },
            saute: {
              time: {
                min: 1,
                max: 5400,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 1,
                        max: 10,
                      },
                      {
                        min: 15,
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 5,
                      },
                    ],
                  },
                ],
              },
              temp: {
                min: 'MAX',
                max: 'MAX',
                step: 0,
              },
              speed: {
                k: 'S',
                v: '1/S',
              },
            },
            knead: {
              time: {
                min: 1,
                max: 180,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 1,
                        max: 10,
                      },
                      {
                        min: 15,
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 5,
                      },
                    ],
                  },
                ],
              },
              temp: null,
              speed: {
                k: 'A',
                v: $rootScope.translations.common.speeds.knead,
              },
            },
            turbo: {
              time: null,
              temp: null,
              speed: {
                k: 'Turbo',
                v: $rootScope.translations.common.speeds.turbo,
              },
              hits: {
                min: 1,
                max: 9,
              },
            },
            progressive: {
              time: {
                min: 10,
                max: 900,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 1,
                        max: 10,
                      },
                      {
                        min: 15,
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 5,
                      },
                    ],
                  },
                ],
              },
              temp: null,
              speed: {
                k: 'Progressiva',
                v: $rootScope.translations.common.speeds.progressive,
              },
              ranges: {
                c: { min: 2, max: 10 },
                h: { min: 2, max: 5 },
              },
            },
            'slow-cook': {
              time: {
                min: 60,
                max: 172800,
                min_unit: 'min',
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 1,
                        max: 10,
                      },
                      {
                        min: 15,
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 5,
                      },
                    ],
                  },
                ],
              },
              temp: {
                min: 37,
                max: 80,
                step: 1,
              },
              speed: null,
            },
            'sous-vide': {
              time: {
                min: 60,
                max: 172800,
                min_unit: 'min',
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 1,
                        max: 10,
                      },
                      {
                        min: 15,
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 5,
                      },
                    ],
                  },
                ],
              },
              temp: {
                min: 37,
                max: 80,
                step: 1,
              },
              speed: null,
            },
            steam: {
              time: {
                min: 1,
                max: 5940,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 1,
                        max: 10,
                      },
                      {
                        min: 15,
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 5,
                      },
                    ],
                  },
                ],
              },
              temp: {
                min: 'VAP',
                max: 'VAP',
                step: 0,
              },
              speed: null,
            },
            kettle: {
              time: null,
              temp: {
                min: 37,
                max: 99,
                step: 1,
              },
              speed: null,
            },
            "high-temperature": {
              time: {
                min: 1,
                max: 5400,
                secs_step: [
                  {
                    max_time: 60,
                    steps: [
                      {
                        step: 1,
                        max: 10,
                      },
                      {
                        min: 15,
                        step: 5,
                      },
                    ],
                  },
                  {
                    max_time: Infinity,
                    steps: [
                      {
                        min: 0,
                        step: 5,
                      },
                    ],
                  },
                ],
              },
              temp: {
                min: 'MAX',
                max: 'MAX',
                step: 0,
              },
              speed: {
                min: 1,
                max: 2,
                step: 1,
                prefix: 'S'
              },
            },
          },
        };

        recipesManager.getParams = function (machine, func, variant) {
          var identifier = variant ? machine + '_' + variant : machine;
          var info = ranges[identifier] ? ranges[identifier][func] : null;

          if (info) {
            var params = {};

            //Temperaturas
            if (info.temp && info.temp.step) {
              params.temp = [];

              for (
                var i = info.temp.min;
                i <= info.temp.max;
                i += info.temp.step
              ) {
                params.temp.push({ k: i.toString(), v: i + 'º' });
              }
            } else if (info.temp) {
              params.temp = [
                {
                  k: info.temp.min.toString(),
                  v: Number.isInteger(info.temp.min)
                    ? info.temp.min + 'º'
                    : info.temp.min,
                },
              ];
            }

            //Velocidades
            if (info.speed && (info.speed.min || info.speed.prefix)) {
              var step = info.speed.step || 1;
              params.speed = [];

              for (var i = info.speed.min; i <= info.speed.max; i += step) {
                params.speed.push({ k: info.speed.prefix ? info.speed.prefix + i : i.toString(), v: i.toString() });
              }

            } else if (info.speed && info.speed.k) {
              params.speed = [];
              params.speed.push(info.speed);
            }

            if (info.ranges) {
              params.ranges = { c: [], h: [] };

              for (var i = info.ranges.c.min; i <= info.ranges.c.max; i++) {
                params.ranges.c.push({ k: i.toString(), v: i.toString() });
              }

              for (var i = info.ranges.h.min; i <= info.ranges.h.max; i++) {
                params.ranges.h.push({ k: i.toString(), v: i.toString() });
              }
            }

            if (info.hits) {
              params.hits = [];

              for (var i = info.hits.min; i <= info.hits.max; i++) {
                params.hits.push({ k: i + 'T', v: i + 'T' });
              }
            }

            //Tiempos
            if (info.time) {
              params.time = {
                h: [],
                m: [],
                s: [],
                max: info.time.max,
                min: info.time.min,
                secs_range: null,
              };

              var time_ranges = {
                h: { min: 0, max: 0 },
                m: { min: 0, max: 0 },
                s: { min: 0, max: 0 },
              };

              if (info.time.min_unit && info.time.min_unit === 'min') {
                time_ranges.h.max = Math.floor(info.time.max / 3600);
                time_ranges.m.max = time_ranges.h.max
                  ? 59
                  : Math.floor(info.time.max / 60);

                time_ranges.m.min = Math.floor(info.time.min / 60);
              } else {
                time_ranges.m.max = Math.floor(info.time.max / 60);
                time_ranges.s.max = time_ranges.m.max
                  ? 59
                  : Math.floor(info.time.max);

                time_ranges.s.min = Math.floor(info.time.min);
              }

              if (time_ranges.h.max) {
                for (var i = time_ranges.h.min; i <= time_ranges.h.max; i++) {
                  params.time.h.push(i);
                }
              }

              if (time_ranges.m.max) {
                for (var i = time_ranges.m.min; i <= time_ranges.m.max; i++) {
                  params.time.m.push(i);
                }

                if (params.time.m[0]) params.time.m.unshift(0);
              }

              if (time_ranges.s.max) {
                params.time.secs_range = time_ranges.s;

                //Aqui pasamos el objeto "descriptor" al controlador, ya que los segundos pueden variar dependiendo de los minutos
                params.time.s = info.time.secs_step;
              }
            }

            return params;
          } else {
            return {};
          }
        };

        recipesManager.getMinutes = function () {
          var minutes = [];

          for (var i = 0; i < 60; i++) minutes.push(i);

          return minutes;
        };

        recipesManager.getSeconds = function () {
          var seconds = [];
          var i = 0;

          while (i < 60) {
            seconds.push(i);

            if (i < 10) i++;
            else i += 5;
          }

          return seconds;
        };

        recipesManager.getAllowedSizes = function (recipeSize) {
          var sizesBase = [
            {
              key: '-',
              value:
                $rootScope.translations.new_recipe.basic_information.undefined,
            },
            { key: '1', value: 1 },
            { key: '2', value: 2 },
            { key: '3', value: 3 },
            { key: '4', value: 4 },
            { key: '5', value: 5 },
            { key: '6', value: 6 },
            { key: '7', value: 7 },
            { key: '8', value: 8 },
            { key: '10', value: 10 },
            { key: '12', value: 12 },
            { key: '14', value: 14 },
            {
              key: '16',
              value:
                '16 ' +
                $rootScope.translations.new_recipe.basic_information.or_more,
            },
          ];

          if (recipeSize) {
            var doneSizes = Object.keys(recipeSize).filter(function (s) {
              return s != 'current';
            });

            return sizesBase.slice(1).filter(function (base) {
              return !doneSizes.includes(base.key);
            });
          } else return sizesBase;
        };

        recipesManager.getFilters = function () {
          var bundle = {};
          bundle.accessoriesNoMycook = ['spatula', 'basket']; //'steamer',
          bundle.mycookFunctions = [
            { key: 'normal', for: ['S1', 'S2'] },
            { key: 'saute', for: ['S1', 'S2'] },
            { key: 'knead', for: ['S1', 'S2'] },
            { key: 'turbo', for: ['S1', 'S2'] },
            { key: 'progressive', for: ['S1', 'S2'] },
            { key: 'slow-cook', for: ['S2'] },
            { key: 'high-temperature', for: ['S2'] },
            { key: 'sous-vide', for: ['S2'] },
            { key: 'steam', for: ['S2'] },
            { key: 'kettle', for: ['S2'] }
          ];

          bundle.noMycookTypes = [
            'bake',
            'fry',
            'coat',
            'manipulation',
            'repose',
            'stew',
            'microwave',
          ];

          bundle.coursesObject = {
            breakfast: ['Breakfast'],
            appetizer: ['Morning snack'],
            snack: ['Afternoon snack'],
            first: ['Lunch starter', 'Diner starter'],
            second: ['Lunch main', 'Diner main'],
            dessert: ['Lunch desert', 'Diner desert'],
          };

          return bundle;
        };

        return recipesManager;
      },
    ])

    .factory('ingredientsManager', [
      '$rootScope',
      '$http',
      '$q',
      '_',
      'appConfig',
      'sessionService',
      'toolsService',
      function (
        $rootScope,
        $http,
        $q,
        _,
        appConfig,
        sessionService,
        toolsService
      ) {
        var ingredients = {};

        ingredients.getIngredients = function () {
          var deferredIngredients;
          if (angular.isUndefined(deferredIngredients)) {
            deferredIngredients = $q.defer();
            var ingredientsBundle = sessionService.getValue('ingredients');

            if (!toolsService.isEmpty(ingredientsBundle)) {
              deferredIngredients.resolve(ingredientsBundle);
            } else {
              var urlDestino = appConfig.mycookTouchDomain + '/ingredients/';
              $http({
                method: 'get',
                url: urlDestino,
              }).then(function (ingredients) {
                ingredientsBundle = ingredients.data;
                sessionService.setValue('ingredients', ingredientsBundle);
                deferredIngredients.resolve(ingredientsBundle);
              });
            }
          }
          return deferredIngredients.promise;
        };

        ingredients.getMeasurements = function () {
          var ingredientMeasures = [
            {
              key: 'tsp',
              name: $rootScope.translations.common.measures.tsp,
            },
            {
              key: 'tbsp',
              name: $rootScope.translations.common.measures.tbsp,
            },
            {
              key: 'drp',
              name: $rootScope.translations.common.measures.drp,
            },
            {
              key: 'gr',
              name: $rootScope.translations.common.measures.gr,
            },
            {
              key: 'l',
              name: $rootScope.translations.common.measures.l,
            },
            {
              key: 'coy',
              name: $rootScope.translations.common.measures.coy,
            },
            {
              key: 'ml',
              name: $rootScope.translations.common.measures.ml,
            },
            {
              key: 'pkt',
              name: $rootScope.translations.common.measures.pkt,
            },
            {
              key: 'pch',
              name: $rootScope.translations.common.measures.pch,
            },
            {
              key: 'scht',
              name: $rootScope.translations.common.measures.scht,
            },
            {
              key: 'slc',
              name: $rootScope.translations.common.measures.slc,
            },
            {
              key: 'bch',
              name: $rootScope.translations.common.measures.bch,
            },
            {
              key: 'cp',
              name: $rootScope.translations.common.measures.cp,
            },
            {
              key: 'u',
              name: $rootScope.translations.common.measures.u,
            },
            {
              key: 'clv',
              name: $rootScope.translations.common.measures.clv,
            },
            {
              key: 'lea',
              name: $rootScope.translations.common.measures.lea,
            },
            {
              key: 'mc',
              name: $rootScope.translations.common.measures.mc,
            },
          ];
          ingredientMeasures = _.orderBy(ingredientMeasures, 'name');

          ingredientMeasures.unshift({
            key: '',
            name: $rootScope.translations.new_recipe.select,
          });

          return ingredientMeasures;
        };

        return ingredients;
      },
    ]);
})();
