(function () {
  'use strict';

  angular.module('mainApp')

    .service('machineService', function ($http, $q, appConfig, toolsService) {

      var _machineService = {};

      _machineService.isEmptyMachine = function (machineData) {

        var urlDestino = appConfig.localApiBasePath + '/checkMachine';

        return $http({
          'method': 'POST',
          url: urlDestino,
          data: machineData
        }).then(function (res) {
          return res;
        }, function (err) {
          return err;
        });
      }

      _machineService.hasDuplicatedMachines = function (machines) {

        if (machines.length == 1) {
          return false;
        } else {
          var machineCodes = [];

          machines.forEach(function (machine) {
            machineCodes.push(String(machine.serial.batch) + String(machine.serial.compatibility_code) + String(machine.serial.counter) + String(machine.serial.control));
          });

          return toolsService.has_duplicates(machineCodes);
        }
      }

      _machineService.getMachineSchema = function () {

        return {
          "serial": {
            "batch": "",
            "compatibility_code": "",
            "counter": "",
            "control": ""
          },
          "purchaseDate": "",
          "purchase_year": "",
          "purchase_month": "",
          "purchase_day": ""
        };
      }

      _machineService.formatMachineList = function (machineList) {

        var arrayMachinesUpdate = [];

        machineList.forEach(function (machine) {

          var fixedDate = toolsService.arrayToTimestamp([machine.purchase_year, machine.purchase_month, machine.purchase_day]);

          var machineTemp = {
            "serial": {
              "batch": machine.serial.batch,
              "compatibility_code": machine.serial.compatibility_code,
              "counter": machine.serial.counter,
              "control": machine.serial.control
            },
            "purchaseDate": fixedDate
          }

          arrayMachinesUpdate.push(machineTemp);
        });

        return arrayMachinesUpdate;
      }

      return _machineService;

    })

    .controller('RegisterController', [function ($scope, $q, $location, $rootScope, $timeout, $log, _, appConfig, usersManager, machineService, toolsService, legalTerms, storeFactory) {
      // https://scotch.io/tutorials/angularjs-form-validation

      $scope.signature_contract = 'deactivated'; // control user-contract

      $scope.cancelContract = function (userLegalData) {
        $scope.signature_contract = 'deactivated';
        $scope.contract_not_accepted = true;
      };

      $scope.acceptContract = function (validForm, userLegalData) {
        $scope.signature_contract = 'deactivated';
        $scope.contract_not_accepted = false;
        createUser();
      };

      // iOS App - address & birth date are not required
      $scope.iosRequiredFields = true;
      if (($scope.deviceDetector.device == 'iphone' || $scope.deviceDetector.device == 'ipad') && $scope.deviceDetector.browser == 'safari') {
        $scope.iosRequiredFields = false;
      }

      if(appConfig.webSettings.shop) {
        // get state list
        storeFactory.getStatesRegister().then(function(states) {
          $scope.states = states;
        });
      }

      //get the enumerations for Dates selects
      $scope.years = toolsService.setYears();
      $scope.months = toolsService.setMonths();
      $scope.days = toolsService.setDays();

      //get the dummy user schema
      $scope.userData = usersManager.getUserSchema();

      //get the dummy machine schema and create the machines array
      $scope.machines = [machineService.getMachineSchema()];

      $scope.addMachine = function () {
        $scope.machines.push(machineService.getMachineSchema());
      }
      $scope.deleteMachine = function (item) {
        var index = $scope.machines.indexOf(item);
        $scope.machines.splice(index, 1);
      }

      $scope.submitForm = function (valid, userData) {
        // TODO: Revisar formulario OK, mirar máquinas no usadas & usernicename no usado anteriormente

        $scope.errorMachines = undefined;
        $scope.errorCorreoUso = undefined;
        $scope.success = undefined;

        if (!machineService.hasDuplicatedMachines($scope.machines)) {

          machinesExistAndFree($scope.machines).then(function (allMachinesGood) {

            if (!allMachinesGood) { //some machines not good, cant create the user
              $scope.errorMachines = true;
            } else { //all machines good, can create the user
              $scope.errorMachines = false;
  
              //parse the birthday to the correct format
              $scope.userData.profile.birthday = toolsService.getStringDate($scope.birth_year, $scope.birth_month, $scope.birth_day);

              $scope.userLegalData = {};

              legalTerms.checkLegalTerms({}).then(function (checkedElements) {
                var minimumLegal = ['memberConditions', 'internationalConditions'];

                checkedElements = _.pick(checkedElements, minimumLegal);

                $log.debug('-------------> checkLegalTerms > checkedElements',checkedElements);
                $log.debug('-------------> checkLegalTerms > checkedElements > size',_.size(checkedElements));
                $log.debug('-------------> checkLegalTerms > checkedElements > isEmpty',_.isEmpty(checkedElements));

                if (_.size(checkedElements) > 0) {
                  $scope.userLegal = checkedElements;

                  $log.debug('REGISTER - checkelements: ', checkedElements);

                  legalTerms.getLegalTerms(minimumLegal).then(function (legal) {
                    $scope.legal = legal;
                    $scope.signature_contract = 'activated';
                  });
                } else { // no tiene que firmar nada
                  createUser();
                }
              });
            }
          });
        } else {
          $scope.errorMachines = true;
        }
      }

      function createUser() {
        var userData = $scope.userData;

        usersManager.createUser(userData).then(function (response) {

          // FEATURE: quedaria molon validar el mail i el login al hacer un focus-out de dichos campos
          $scope.errorCorreoUso = false;

          if (response.status == 201) {
            // TODO: XXXX - Low - informar al usuario de que tiene que validar su cuenta desde un correo que se ha enviado a su mail
            var userLogin = response.data.niceName;
            var machinesUpdateFormat = machineService.formatMachineList($scope.machines);

            // puc llançar la actualitzacio sobre les machines
            usersManager.updateMachineList(machinesUpdateFormat, response.data.niceName).then(function (response) {

              if (response.status == '200') { //les maquines s'han assignat correctament

                $scope.success = true;

                //login i redirect a la home
                usersManager.loginUser(userLogin, userData.password).then(function (userInfo) {
                  $rootScope.$emit('userLoggedIn', {
                    'niceName': userInfo.niceName
                  });

                  // SEND LEGAL TERMS
                  var sendToApi = new Array();
                  sendToApi.push('communityConditions');

                  for (var k in $scope.userLegalData) {
                    if ($scope.userLegalData[k]) {
                      sendToApi.push(k);
                    }
                  }

                  legalTerms.acceptLegalTerms('multi', sendToApi).then(function (result) {
                    $timeout(function () {
                      $location.path($rootScope.url('home'));
                    }, 1500);

                  }, function (err) {
                    $log.error('error sending policy', err);
                  });
                }, function (e) {
                  $log.error(e);
                });

              } else {
                $scope.success = false;
              }
            });

          } else if (response.status == 409) {
            $scope.errorCorreoUso = true;

            if ($rootScope.translations.user_profile.messages[response.data.errorCode]) {
              $scope.errorCorreoCopy = $rootScope.translations.user_profile.messages[response.data.errorCode];
            } else {
              $scope.errorCorreoCopy = $rootScope.translations.user_profile.messages.error;
            }

          } else {
            $scope.success = false;
          }
        });
      }

      function machinesExistAndFree(machines) {

        var defer = $q.defer();

        var responses = [];
        var final_response_value = false;

        machines.forEach(function (machine, index) {
          machine.serial.compatibility_code = (machine.serial.compatibility_code).toUpperCase();
          responses[index] = machineService.isEmptyMachine(machine.serial);
        });

        $q.all(responses).then(function (all_result) {

          var final_results = [];

          all_result.forEach(function (result, index) {

            console.log(result)

            if (result.data === null || result.status == 404) {
              final_results[index] = false;
            } else {

              if (result.data.info && !toolsService.isEmpty(result.data.info.owner)) {
                final_results[index] = false;
              } else {
                final_results[index] = true; //la maquina existeix i esta lliure
              }
            }
          });


          final_results.forEach(function (result) {

            if (!result) {
              final_response_value = false;
            } else {
              final_response_value = true;
            }
          });

          defer.resolve(final_response_value);

        });

        return defer.promise;
      }
    }])
})();